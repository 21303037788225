// Generated by Framer (cf240c2)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["hQYsCKPR5", "ybnqBTTkd"];

const serializationHash = "framer-JU61f"

const variantClassNames = {hQYsCKPR5: "framer-v-1qe6hat", ybnqBTTkd: "framer-v-1cpsyd8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 2": "ybnqBTTkd", Primary: "hQYsCKPR5"}

const getProps = ({height, id, scroll1, width, ...props}) => { return {...props, hkER3s2E3: scroll1 ?? props.hkER3s2E3 ?? {src: "https://framerusercontent.com/images/eZesoO6hDlRGcWJP8VdOOcb1Srg.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/eZesoO6hDlRGcWJP8VdOOcb1Srg.png 725w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hQYsCKPR5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;scroll1?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hkER3s2E3, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hQYsCKPR5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1ix3xiq = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2750)
})

useOnVariantChange(baseVariant, {default: onAppear1ix3xiq})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1qe6hat", className, classNames)} data-framer-name={"Primary"} data-highlight layoutDependency={layoutDependency} layoutId={"hQYsCKPR5"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} {...addPropertyOverrides({ybnqBTTkd: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1qgl0oh"} layoutDependency={layoutDependency} layoutId={"XRoR2ZXaH"} style={{backgroundColor: "rgb(44, 44, 44)"}}><ComponentViewportProvider ><motion.div className={"framer-ltj7vw-container"} layoutDependency={layoutDependency} layoutId={"ZSrERVXtx-container"}><Ticker alignment={"center"} direction={"top"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={0} height={"100%"} hoverFactor={0.8} id={"ZSrERVXtx"} layoutId={"ZSrERVXtx"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[<Image background={{alt: "", fit: "stretch", sizes: "334px", ...toResponsiveImage(hkER3s2E3), ...{ positionX: "center", positionY: "center" }}} className={"framer-1o5of8e"} data-framer-name={"Feed1"} layoutDependency={layoutDependency} layoutId={"Lm4CAyG8X"}/>]} speed={100} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JU61f.framer-kh2w7k, .framer-JU61f .framer-kh2w7k { display: block; }", ".framer-JU61f.framer-1qe6hat { height: 724px; overflow: hidden; position: relative; width: 334px; will-change: var(--framer-will-change-override, transform); }", ".framer-JU61f .framer-1qgl0oh { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 100%; justify-content: center; left: 0px; overflow: visible; padding: 0px; position: absolute; top: 0px; width: 100%; }", ".framer-JU61f .framer-ltj7vw-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", ".framer-JU61f .framer-1o5of8e { height: 724px; overflow: visible; position: relative; width: 334px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JU61f .framer-1qgl0oh { gap: 0px; } .framer-JU61f .framer-1qgl0oh > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-JU61f .framer-1qgl0oh > :first-child { margin-top: 0px; } .framer-JU61f .framer-1qgl0oh > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 724
 * @framerIntrinsicWidth 334
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ybnqBTTkd":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"hkER3s2E3":"scroll1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergEMg7fFl3: React.ComponentType<Props> = withCSS(Component, css, "framer-JU61f") as typeof Component;
export default FramergEMg7fFl3;

FramergEMg7fFl3.displayName = "EndlessFeedContent";

FramergEMg7fFl3.defaultProps = {height: 724, width: 334};

addPropertyControls(FramergEMg7fFl3, {variant: {options: ["hQYsCKPR5", "ybnqBTTkd"], optionTitles: ["Primary", "Variant 2"], title: "Variant", type: ControlType.Enum}, hkER3s2E3: {__defaultAssetReference: "data:framer/asset-reference,eZesoO6hDlRGcWJP8VdOOcb1Srg.png?originalFilename=Screenshot+2024-07-03+162004.png&preferredSize=auto", title: "scroll1", type: ControlType.ResponsiveImage}} as any)

addFonts(FramergEMg7fFl3, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})